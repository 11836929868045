.wedo {

  &__h4 {
    font-size: 24px;
    font-weight: bold;
    color: $textPrimary;
  }

  &__h2  {
    font-size: 32px;
    text-align: center;
    line-height: normal;
    margin: 0 0 40px;
    text-transform: uppercase;

    @include media-breakpoint-down(xs) {
      font-size: 18px;
      margin: 0 0 20px;
    }
    &:after {
      display: none;
    }

    &_small {
      font-size: 24px;
      @include media-breakpoint-down(xs) {
        font-size: 14px;
      }
    }

  }

  &__p-home {
    font-size: 18px;
    //font-weight: bold;
    color: $textPrimary;
  }

}
.d-none {
  display: none;
}
p {
  font-size: $fsMain;
  color: $textPrimary;
}

h2 {
  font-size: $fsSecondTitle;
  @include media-breakpoint-down(xs) {
    font-size: 14px;
  }
}

footer p {
  font-size: 12px;
}

.wedo {
  text-align: center;
  margin-bottom: 10px;
  & a {
    color:  #8c8f91;
    font-size: 12px;
    line-height: 16px;
    text-decoration: none;
    &:hover {
      color: #9da0a2;
    }
  }
  & img {
    width: 63px;
    margin-bottom: 4px;
  }
}

.icon-checkbox:before {
  content: '\e174';
  padding-right: 10px;
  color: $primary;
  font-size: 40px;
  line-height: 40px;
  font-family: $material;
}

.video {
  position:relative;
  padding-bottom:54.5%;
  padding-top:25px;
  height:0;
  iframe {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
  }
}

h1:after {
  margin: 20px 0 20px;
}

#content {
  padding-bottom: 0;
}

#common-home:after {
  content: none;
}

.pagination>li+li:nth-child(n+6) {
  margin-top: 0;
}
.pagination>li+li:nth-child(5n+6) {
  clear: none;
}

.wedo-auth {
  .btn-primary {
    min-width: 250px;
  }
}