
.xreview-content {
  @include media-breakpoint-down(xs) {
    .xreviews-meta {
      text-align: center;
      margin-top: 10px;
    }
    .xreview-title {
      text-align: center;
    }
  }

}

.spoiler {
  overflow: hidden;
}
.xreview-text.spoiler {
  margin-bottom: 0;
}