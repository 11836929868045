.changeLanguage {

  display: flex;
  align-items: center;
  margin-bottom: 10px;

  &__title {
    font-size: 16px;
    margin-right: 5px;
  }

  &__title {}
}

footer .box-language .toggle {
  padding: 15px 24px;
}