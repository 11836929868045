.wedo-fa-youtube-play {
  color: $primary;
  position: absolute;
  top: 50%;
  font-size: 100px;
  margin-top: -100px;
  margin-left: -50px;
  left: 50%;
}


a[data-fancybox="images"]:hover {
  opacity: 0.75;
  .wedo-fa-youtube-play {
    color: $orange;
  }
}

.fancybox-caption {
  @media screen and (max-width: 370px) {
    &__body {
      font-size: 13px;
    }
  }
}