.four-banners {

  &__wrap-banner {
    width: 300px;
    height: 170px;
    margin: 35px;
    color: $textPrimary;
    font-size: 24px;
    font-weight: bold;
    border: 1px solid $textPrimary;
    p {
      margin-bottom: 0;
    }
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &_right {
      float: right;
    }

  }
  
  &__wrap {
    margin: 25px 0;
  }


}

