.wedo-header-top {

  .box-nav {
    > * {
      + * {
        &:before {
          display: none;
        }
      }
    }
  }

  .box-nav>*+* {
    margin-left: 0;
    margin-top: 2px;
  }

  .box-language, .box-nav {
    float: right;
    margin-bottom: 0;
  }

  .box-nav {
    &.box-nav_mt {
      margin-top: -27px;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    @include media-breakpoint-down(xs) {
      margin-top: 15px;
      &.box-nav_mt {
        margin-top: 15px;
      }
      width: 100%;
    }
    &:before {
      display: none !important;
    }
    a {
      font-size: 14px;
      border: 1px solid $textPrimary;
      padding: 15px 15px;
      color: $textPrimary;
      min-width: 160px;
      width: 100%;
      @include media-breakpoint-down(xs) {
        width: 70%;
      }
      &:hover {
        background-color: $primary;
        color: $orange;
      }
    }

  }
}

header .logo {
  margin-top: 0;
  margin-bottom: 10px;
}

.common-home .mid-line {
  background: $white;
}

.rd-mobilemenu_ul a.active, .rd-mobilemenu_ul a:hover {
  color:$baseColor;
  background-color: transparent;
}