.desc-home {

  &__list-wrap {
    display: flex;
    justify-content: center;
  }

  &__list {
    list-style: none;
    display: inline-block;
    padding: 0;
    li{
      margin: 10px;
      font-size: 18px;
      //font-weight: bold;
      color: $textPrimary;
      display: flex;
      //justify-content: center;
      align-items: center;
    }
    li:before {
      content: '\e174';
      padding-right: 10px;
      //position: absolute;
      //left: 0;
      //bottom: -7px;
      color: $primary;
      font-size: 40px;
      line-height: 40px;
      font-family: $material;
    }
  }

}