.qr {

  &__title {
    text-align: center;
    margin: 10px 0;
  }
  &__img-wrap {

  }

}