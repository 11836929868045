.product-thumb {

  .name {
    white-space: normal;
    min-height: 46px;
    text-transform: uppercase;
    text-align: center;
  }

  .price {
    line-height: 23px;
  }

  .short-caption {
    height: 60px;
    text-align: center;
    p {
      font-size: 14px;
    }

  }

}
.product_tabs {
  .table>tbody>tr>td {
    border: none;
    vertical-align: middle;
  }
  .table thead td {
    background-color: transparent;
    font-size: $fsMain;
    color: $textPrimary;
  }
  .table {
    font-size: $fsMain;
    color: $textPrimary;
  }
}

#product_tabs_target {
  @include media-breakpoint-down(sm) {
    height: 7px;
  }
}

.s-desc {

  &_top {
    top: 60px !important;
    @include media-breakpoint-down(xs) {
      top: 40px !important;
    }
    left: 0 !important;
    text-align: center !important;
    width: 100%;
    @include media-breakpoint-down(xs) {
      left: 50%!important;
    }
  }

  &_black {
    color: $black !important;
  }

}

.product-layout.product-list {

    @include media-breakpoint-up(md) {
      .name {
        font-size: 28px;
        padding-top: 5px;
      }
      .price,.price-new,.price-old {
        font-size: 22px;
      }
    }


}

.product-info {
  .product-title {
    margin: 0 0 30px;
  }
  .short-description {
    padding: 15px 0 5px;
  }
}

.product_tabs {
  @include media-breakpoint-up(sm) {
    margin: 0;
  }
}
#product .modal label {
  margin: 10px 0;
}

.col-sm-12 .product-layout:nth-child(4n+5) {
  clear: none;
}