@for $i from 0 through 100 {
  .mt-#{$i}  {
    margin-top: #{$i}px;
  }
  .mb-#{$i}  {
    margin-bottom: #{$i}px;
  }
  .mr-#{$i}  {
    margin-right: #{$i}px;
  }

  .px-#{$i}  {
    padding-left: #{$i}px;
    padding-right: #{$i}px;
  }

  @each $breakpoint in map-keys($grid-breakpoints) {

    .mt-#{$i}-#{$breakpoint}  {
      @include media-breakpoint-up($breakpoint) {
        margin-top: #{$i}px;
      }
    }

    .mb-#{$i}-#{$breakpoint}  {
      @include media-breakpoint-up($breakpoint) {
        margin-bottom: #{$i}px;
      }
    }

    .m-#{$i}-#{$breakpoint}  {
      @include media-breakpoint-up($breakpoint) {
        margin: #{$i}px;
      }
    }

  }

}

@each $breakpoint in map-keys($grid-breakpoints) {

  .float-#{$breakpoint}-right  {
    @include media-breakpoint-down($breakpoint) {
      float: right !important;
    }
  }
  .float-#{$breakpoint}-left  {
    @include media-breakpoint-down($breakpoint) {
      float: left !important;
    }
  }

}

