.banners-advantages {
  .banner-box {
    .banner_img {
      @media (max-width: 767px) {
        width: 100%;
      }
    }
  }
}

.banner-mt-40 {
  @include media-breakpoint-up(sm) {
    margin-top: 30px;
  }
  @include media-breakpoint-up(lg) {
    margin-top: 40px;
  }
}

.home-block-mt {
  margin-top: 100px;
  @include media-breakpoint-down(xs) {
    margin-top: 50px;
  }
}