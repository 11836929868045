//@import "../zemez889/style";

@import "../zemez889/include/_variables.scss";
@import "../zemez889/include/_mixins.scss";

@import "include/breakpoints";
@import "include/variables";
@import "include/spaces";
// blocks
@import 'blocks/header';
@import 'blocks/footer';
@import 'blocks/footer/changeLanguage';
@import "blocks/header/sf-menu";
@import 'blocks/menu';
@import 'blocks/home';
@import "blocks/home/videobanner";
@import "blocks/home/desc-home";
@import "blocks/home/four-banners";
@import "blocks/header/wedo-desc-header";
@import "blocks/footer/qr";
@import "blocks/catalog";
@import "blocks/news";
@import "blocks/information/wedo-banner";

@import 'blocks/adv-number';

@import "blocks/diag-test";
@import "blocks/reviews";
@import "blocks/wedo";
@import "blocks/gallery";

input.website { display: none; }

#content{
  .list-group-availableLanguage a.list-group-item {
    color: #555;
  }
}