footer {
  .box-language {
    display: inline-block;
    font-size: 14px;
    border: 1px solid $textPrimary;

    .toggle {
      padding: 15px 15px;
    }

    .toggle_cont {
      top: auto;
      bottom: calc(100% + 1px);
      right: 0;
      border: 1px solid $bgGray;
      color: $orange;
      width: 100%;
      li {
        margin-top: 0;
      }
      @include media-breakpoint-down(sm) {
        right: 0;
        left: auto;
      }
    }

    &:hover {
      background-color: $primary;
      color: $orange;
      .toggle, .toggle:hover {
        background-color: $primary;
        color: $orange;
      }
    }

    .toggle.active {
      color: $orange;
      background-color: $primary;
    }

    li>button.language-select {
      background-color: $white;
      color: $textPrimary;
      &.selected,&.selected:hover {
        background-color: $primary;
        color: $white;
      }
      &:hover {
        color: $orange;
      }
    }

  }
}