/*================ Menu  ===============*/
.sf-menu {
  > li {
    > a {
      text-transform:uppercase;
      font-weight: 700;
      @include border-radius(3px);
      font-size: 12px;
    }
  }
  li {
    position: relative;
    > a {
      &, &:visited, &:focus {
        color: $white;
      }
    }
    &.sfHover > a, > a:hover, > a.active {
      color: $orange;
    }
    &.sf-with-mega {
      position: static;
    }
  }
}