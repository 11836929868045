.wedo-desc-header {

  text-align: right;

  &__p {

    color: $textPrimary;
    font-size: 24px;
    @include media-breakpoint-down(md) {
      font-size: 16px;
    }
    margin-bottom: 0;

    &_main {
      font-weight: bold;
    }

  }

}