.question {
  margin-top: 30px;

  &__icon-check {
    display: none;
    &_checked {
      display: block;
    }
    &:before {
      content: '\e174';
      position: absolute;
      left: 50%;
      bottom: 5px;
      color: $primary;
      font-size: 40px;
      line-height: 40px;
      font-family: $material;
      margin-left: 90px;
    }
  }
}