$margins: 65px;
.adv_number {
  margin-top: -$margins;
  margin-bottom: $margins;
  p > span {
    color: $primary;
    font-size: 55px;
    //letter-spacing: -5px;
  }
  p {
    text-align: center;
    color: $black;
    height: 100px;
    margin-top: $margins;
  }
}