.sf-menu {
  .sf-mega {
    margin-top: 1px;
    left: auto;
    right: auto;
    width: 300px;
    margin-left: -3%;
    background-color: $primary;
    a {
      color: white;
    }
    &>li a:hover {
      color: $orange;
    }
    .sf-mega_section {
      text-transform: uppercase;
    }
  }
}
