.wedo-banner {

  margin-bottom: 100px;

  &__img{
    img {
      @include media-breakpoint-down(sm) {
        width: 50%;
      }
    }

  }

  &__title{
    font-size: 24px;
    line-height: 31px;
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: $black !important;
    margin-top: 40px;
    @media (max-width: 991px) {
      font-size: 15px;
      line-height: 18px;
    }
    @include media-breakpoint-down(sm) {
      text-align: center;
      margin-top: 15px;
    }

  }

  &__link{

    width: 100%;
    &:hover {
      opacity: 0.7;
    }
  }

  &__col{
    &_mt {
      @include media-breakpoint-down(md) {
        margin-top: 50px;
      }
    }
  }

  &__title{}
}
